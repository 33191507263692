import Trending from 'icons/Trending';
import Dashboard from 'icons/Dashboard';
import Male from 'icons/Male';
import Female from 'icons/Female';
import Permission from 'icons/Permission';
import UsersIcon from 'icons/Users';
import Subscription from 'icons/Subscription';
import Email1 from 'icons/Email1';
import Email2 from 'icons/Email2';
import Notifications from 'icons/Notification';
import Settings from 'icons/Settings';
import Search from 'icons/Search';
import LookOrange from 'icons/LookOrange';
import ChatAlt from 'icons/ChatAlt';

const SystemPermissions: any = {
  dashboard: {
    key: 'dashboard',
    icon: <Dashboard fontSize='medium' />,
  },
  'dashboard-view-active-users-chart': {
    key: 'dashboard-view-active-users-chart',
    icon: <Dashboard fontSize='medium' />,
  },
  'dashboard-view-matches-meter-chart': {
    key: 'dashboard-view-matches-meter-chart',
    icon: <Dashboard fontSize='medium' />,
  },
  'dashboard-view-total-user-count-cards': {
    key: 'dashboard-view-total-user-count-cards',
    icon: <Dashboard fontSize='medium' />,
  },
  'dashboard-view-users-activity-chart': {
    key: 'dashboard-view-users-activity-chart',
    icon: <Dashboard fontSize='medium' />,
  },
  'dashboard-view-users-matrix-chart': {
    key: 'dashboard-view-users-matrix-chart',
    icon: <Dashboard fontSize='medium' />,
  },
  'dashboard-view-total-subscribers-chart': {
    key: 'dashboard-view-total-subscribers-chart',
    icon: <Dashboard fontSize='medium' />,
  },
  'dashboard-view-subscriber-types-chart': {
    key: 'dashboard-view-subscriber-types-chart',
    icon: <Dashboard fontSize='medium' />,
  },
  'dashboard-view-subscriber-package-chart': {
    key: 'dashboard-view-subscriber-package-chart',
    icon: <Dashboard fontSize='medium' />,
  },
  trending: {
    key: 'trending',
    icon: <Trending fontSize='medium' />,
  },
  'view-trending-users': {
    key: 'view-trending-users',
    icon: <Trending fontSize='medium' />,
  },
  'user-checker': {
    key: 'user-checker',
    icon: null,
  },
  'user-checker-male-user-checker': {
    key: 'user-checker-male-user-checker',
    icon: <Male fontSize='medium' style={{ color: '#00C5E2' }} />,
  },
  'user-checker-female-user-checker': {
    key: 'user-checker-female-user-checker',
    icon: <Female fontSize='medium' style={{ color: 'primary.main' }} />,
  },
  'user-checker-wali-user-checker': {
    key: 'user-checker-wali-user-checker',
    icon: <UsersIcon fontSize='medium' style={{ color: '#25A297' }} />,
  },
  'app-user': {
    key: 'app-user',
    icon: <UsersIcon fontSize='medium' style={{ color: '' }} />,
  },
  'app-user-search-app-users': {
    key: 'app-user-search-app-users',
    icon: <Search fontSize='medium' />,
  },
  'app-user-view-app-user-details': {
    key: 'app-user-view-app-user-details',
    icon: null,
  },
  'app-user-delete-app-user': {
    key: 'app-user-delete-app-user',
    icon: null,
  },
  'app-user-block-unblock-app-user': {
    key: 'app-user-block-unblock-app-user',
    icon: <LookOrange fontSize='medium' />,
  },
  'app-user-view-app-user-profile': {
    key: 'app-user-view-app-user-profile',
    icon: null,
  },
  'app-user-delete-app-user-profile-photo': {
    key: 'app-user-delete-app-user-profile-photo',
    icon: null,
  },
  'app-user-flag-app-user-profile-photo': {
    key: 'app-user-flag-app-user-profile-photo',
    icon: null,
  },
  'app-user-delete-flag-app-user-profile-photo': {
    key: 'app-user-delete-flag-app-user-profile-photo',
    icon: null,
  },
  'app-user-view-app-user-match-activities': {
    key: 'app-user-view-app-user-match-activities',
    icon: null,
  },
  'app-user-view-app-user-like-activities': {
    key: 'app-user-view-app-user-like-activities',
    icon: null,
  },
  'app-user-view-app-user-reported': {
    key: 'app-user-view-app-user-reported',
    icon: null,
  },
  'app-user-view-app-user-flagged-photos': {
    key: 'app-user-view-app-user-flagged-photos',
    icon: null,
  },
  subscription: {
    key: 'subscription',
    icon: <Subscription fontSize='medium' />,
  },
  'view-subscriptions': {
    key: 'view-subscriptions',
    icon: <Subscription fontSize='medium' />,
  },
  chat: {
    key: 'chat',
    icon: <ChatAlt fontSize='medium' />,
  },
  'chat-view-rooms': {
    key: 'chat-view-rooms',
    icon: <ChatAlt fontSize='medium' />,
  },
  'chat-view-room-messages': {
    key: 'chat-view-room-messages',
    icon: <ChatAlt fontSize='medium' />,
  },
  'chat-send-message-to-room': {
    key: 'chat-send-message-to-room',
    icon: <ChatAlt fontSize='medium' />,
  },
  'chat-send-message-to-default-room': {
    key: 'chat-send-message-to-default-room',
    icon: <ChatAlt fontSize='medium' />,
  },
  'app-notification': {
    key: 'app-notification',
    icon: <Notifications fontSize='medium' />,
  },
  'app-notification-view-app-notifications': {
    key: 'app-notification-view-app-notifications',
    icon: <Notifications fontSize='medium' />,
  },
  'app-notification-create-app-notification': {
    key: 'app-notification-create-app-notification',
    icon: <Notifications fontSize='medium' />,
  },
  'app-notification-view-app-notification-details': {
    key: 'app-notification-view-app-notification-details',
    icon: <Notifications fontSize='medium' />,
  },
  'app-notification-edit-app-notification': {
    key: 'app-notification-edit-app-notification',
    icon: <Notifications fontSize='medium' />,
  },
  'app-notification-delete-app-notification': {
    key: 'app-notification-delete-app-notification',
    icon: <Notifications fontSize='medium' />,
  },
  'app-email': {
    key: 'app-email',
    icon: <Email1 fontSize='medium' />,
  },
  'app-email-view-app-emails': {
    key: 'app-email-view-app-emails',
    icon: <Email1 fontSize='medium' />,
  },
  'app-email-create-app-email': {
    key: 'app-email-create-app-email',
    icon: <Email1 fontSize='medium' />,
  },
  'app-email-view-app-email-details': {
    key: 'app-email-view-app-email-details',
    icon: <Email1 fontSize='medium' />,
  },
  'app-email-edit-app-email': {
    key: 'app-email-edit-app-email',
    icon: <Email1 fontSize='medium' />,
  },
  'app-email-delete-app-email': {
    key: 'app-email-delete-app-email',
    icon: <Email1 fontSize='medium' />,
  },
  setting: {
    key: 'setting',
    icon: <Settings fontSize='medium' />,
  },
  'setting-view-contact-information': {
    key: 'setting-view-contact-information',
    icon: <Settings fontSize='medium' />,
  },
  'setting-edit-contact-information': {
    key: 'setting-edit-contact-information',
    icon: <Settings fontSize='medium' />,
  },
  'setting-view-terms-condition': {
    key: 'setting-view-terms-condition',
    icon: <Settings fontSize='medium' />,
  },
  'setting-edit-terms-condition': {
    key: 'setting-edit-terms-condition',
    icon: <Settings fontSize='medium' />,
  },
  'setting-view-privacy-policy': {
    key: 'setting-view-privacy-policy',
    icon: <Settings fontSize='medium' />,
  },
  'setting-edit-privacy-policy': {
    key: 'setting-edit-privacy-policy',
    icon: <Settings fontSize='medium' />,
  },
  'email-template': {
    key: 'email-template',
    icon: <Email2 fontSize='medium' />,
  },
  'email-template-view-welcome-mail': {
    key: 'email-template-view-welcome-mail',
    icon: <Email2 fontSize='medium' />,
  },
  'email-template-edit-welcome-mail': {
    key: 'email-template-edit-welcome-mail',
    icon: <Email2 fontSize='medium' />,
  },
  'email-template-view-reset-password-mail': {
    key: 'email-template-view-reset-password-mail',
    icon: <Email2 fontSize='medium' />,
  },
  'email-template-edit-reset-password-mail': {
    key: 'email-template-edit-reset-password-mail',
    icon: <Email2 fontSize='medium' />,
  },
  'email-template-view-subscription-mail': {
    key: 'email-template-view-subscription-mail',
    icon: <Email2 fontSize='medium' />,
  },
  'email-template-edit-subscription-mail': {
    key: 'email-template-edit-subscription-mail',
    icon: <Email2 fontSize='medium' />,
  },
  'email-template-view-match-mail': {
    key: 'email-template-view-match-mail',
    icon: <Email2 fontSize='medium' />,
  },
  'email-template-edit-match-mail': {
    key: 'email-template-edit-match-mail',
    icon: <Email2 fontSize='medium' />,
  },
  'email-template-view-like-mail': {
    key: 'email-template-view-like-mail',
    icon: <Email2 fontSize='medium' />,
  },
  'email-template-edit-like-mail': {
    key: 'email-template-edit-like-mail',
    icon: <Email2 fontSize='medium' />,
  },
};

export const getPermissionIcon = (key: string) => {
  let icon = SystemPermissions[key]?.icon;
  if (icon == null) icon = <Permission fontSize='medium' />;

  return icon;
};

export const permission = {
  dashboard_view_active_users_chart: 'dashboard-view-active-users-chart',
  dashboard_view_matches_meter_chart: 'dashboard-view-matches-meter-chart',
  dashboard_view_total_user_count_cards: 'dashboard-view-total-user-count-cards',
  dashboard_view_users_activity_chart: 'dashboard-view-users-activity-chart',
  dashboard_view_users_matrix_chart: 'dashboard-view-users-matrix-chart',
  dashboard_view_total_subscribers_chart: 'dashboard-view-total-subscribers-chart',
  dashboard_view_subscriber_types_chart: 'dashboard-view-subscriber-types-chart',
  dashboard_view_subscriber_package_chart: 'dashboard-view-subscriber-package-chart',
  dashboard_view_tickets_meter_chart: 'dashboard-view-tickets-meter-chart',
  dashboard_view_tickets_statistics_chart: 'dashboard-view-tickets-statistics-chart',
  dashboard_view_tickets_overview_chart: 'dashboard-view-tickets-overview-chart',
  view_trending_users: 'view-trending-users',
  user_bio_checker_male_user_checker: 'user-bio-checker-male-user-checker',
  user_bio_checker_female_user_checker: 'user-bio-checker-female-user-checker',
  user_checker_male_user_checker: 'user-checker-male-user-checker',
  user_checker_female_user_checker: 'user-checker-female-user-checker',
  user_checker_wali_user_checker: 'user-checker-wali-user-checker',
  app_user_search_app_users: 'app-user-search-app-users',
  app_user_view_app_user_profile: 'app-user-view-app-user-profile',
  app_user_delete_app_user_profile_photo: 'app-user-delete-app-user-profile-photo',
  app_user_flag_app_user_profile_photo: 'app-user-flag-app-user-profile-photo',
  app_user_delete_flag_app_user_profile_photo: 'app-user-delete-flag-app-user-profile-photo',
  app_user_view_app_user_match_activities: 'app-user-view-app-user-match-activities',
  app_user_view_app_user_like_activities: 'app-user-view-app-user-like-activities',
  app_user_view_app_user_reported: 'app-user-view-app-user-reported',
  app_user_view_app_user_flagged_photos: 'app-user-view-app-user-flagged-photos',
  app_user_view_app_user_subscriptions: 'app-user-view-app-user-subscriptions',
  app_user_view_app_user_coins: 'app-user-view-app-user-coins',
  app_user_delete_app_user: 'app-user-delete-app-user',
  app_user_block_unblock_app_user: 'app-user-block-unblock-app-user',

  view_subscriptions: 'view-subscriptions',
  wali_payment_view_wali_payments: 'view-wali-payments',

  chat_view_rooms: 'chat-view-rooms',
  chat_view_room_messages: 'chat-view-room-messages',
  chat_send_message_to_room: 'chat-send-message-to-room',
  chat_send_message_to_default_room: 'chat-send-message-to-default-room',

  customer_service_view_tickets: 'customer-service-view-tickets',
  customer_service_view_ticket_details: 'customer-service-view-ticket-details',
  customer_service_view_ticket_messages: 'customer-service-view-ticket-messages',
  customer_service_reply_on_ticket: 'customer-service-reply-on-ticket',
  customer_service_create_ticket: 'customer-service-create-ticket',
  customer_service_change_ticket_status: 'customer-service-change-ticket-status',
  customer_service_set_ticket_priority: 'customer-service-set-ticket-priority',
  customer_service_assign_admin_to_ticket: 'customer-service-assign-admin-to-ticket',
  customer_service_delete_ticket: 'customer-service-delete-ticket',
  cs_dashboard: 'cs-dashboard',
  cs_dashboard_view_tickets_comparison_cards: 'cs-dashboard-view-tickets-comparison-cards',
  cs_dashboard_view_tickets_overview_chart: 'cs-dashboard-view-tickets-overview-chart',
  cs_dashboard_view_tickets_statistics_chart: 'cs-dashboard-view-tickets-statistics-chart',

  app_notification_view_app_notifications: 'app-notification-view-app-notifications',
  app_notification_create_app_notification: 'app-notification-create-app-notification',
  app_notification_view_app_notification_details: 'app-notification-view-app-notification-details',
  app_notification_edit_app_notification: 'app-notification-edit-app-notification',
  app_notification_delete_app_notification: 'app-notification-delete-app-notification',
  app_email_view_app_emails: 'app-email-view-app-emails',
  app_email_create_app_email: 'app-email-create-app-email',
  app_email_view_app_email_details: 'app-email-view-app-email-details',
  app_email_edit_app_email: 'app-email-edit-app-email',
  app_email_delete_app_email: 'app-email-delete-app-email',
  setting_view_contact_information: 'setting-view-contact-information',
  setting_edit_contact_information: 'setting-edit-contact-information',
  setting_view_terms_condition: 'setting-view-terms-condition',
  setting_edit_terms_condition: 'setting-edit-terms-condition',
  setting_view_privacy_policy: 'setting-view-privacy-policy',
  setting_edit_privacy_policy: 'setting-edit-privacy-policy',
  email_template_view_welcome_mail: 'email-template-view-welcome-mail',
  email_template_edit_welcome_mail: 'email-template-edit-welcome-mail',
  email_template_view_reset_password_mail: 'email-template-view-reset-password-mail',
  email_template_edit_reset_password_mail: 'email-template-edit-reset-password-mail',
  email_template_view_subscription_mail: 'email-template-view-subscription-mail',
  email_template_edit_subscription_mail: 'email-template-edit-subscription-mail',
  email_template_view_match_mail: 'email-template-view-match-mail',
  email_template_edit_match_mail: 'email-template-edit-match-mail',
  email_template_view_like_mail: 'email-template-view-like-mail',
  email_template_edit_like_mail: 'email-template-edit-like-mail',
};
