import type { FC } from 'react';
import { Card, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import { curd } from 'api/curd';
import { useQuery } from 'react-query';
import { CSMessageResponse } from 'dto/ticket/ticket-message/inedx';
import { AllMessages } from './AllMessages';
import ScrollBar from 'components/Scrollbar';
import { MessageBox } from './MessageBox';
import { Ticket, TicketStatuses } from 'dto/ticket';
import useAuth from 'hooks/useAuth';

interface Props {
  ticket: Ticket;
}

let res: any = null;

export const pushNewTicketMessageFromNotification = () => {
  if (res != null) {
    res.refetch();
  }
};

export const Messages: FC<Props> = ({ ticket }) => {
  const params = useParams();
  const auth = useAuth();

  res = useQuery(
    `dashboard/ticket/${params?.id}/message`,
    () => curd.getAll({ url: `dashboard/ticket/${params?.id}/message` }),
    {
      refetchInterval: 30 * 1000,
    }
  );

  if (res?.isLoading) {
    return <></>;
  }
  const messages: CSMessageResponse[] = res?.data?.data?.data;

  return (
    <Card sx={{ width: '100%' }}>
      <Box
        component={ScrollBar}
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      >
        <Box>
          <Typography sx={{ px: 4, pt: 2 }} variant='h4'>
            Conversations
          </Typography>
          <AllMessages messages={messages} />
        </Box>
        <MessageBox
          refetch={() => {
            res?.refetch();
          }}
          disabled={
            ticket.status != TicketStatuses.IN_PROGRESS || ticket?.admin?.id != auth?.user?.id
          }
        />
      </Box>
    </Card>
  );
};
