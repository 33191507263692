/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { NAVBAR } from 'constants/config';
import {
  // Avatar,
  Box,
  // Button,
  // Divider,
  Drawer,
  Hidden,
  // Link,
  // Typography,
} from '@mui/material';
// import useAuth from 'hooks/useAuth';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
import useResponsive from 'hooks/useResponsive';
import CollapseButton from './CollapseButton';
import { sections, superAdminSections, devAdminSections } from './Sections';

import Logo from 'components/Logo';
import NavSection from 'components/NavSection';
import Scrollbar from 'components/Scrollbar';
import EmailIcon from 'icons/Email1';
import HTMLIcon from 'icons/HTMLIcon';
import CSSIcon from 'icons/CSSIcon';
import { useQuery } from 'react-query';
import { curd } from 'api/curd';
import { TicketReplyStatuses, TicketStatuses } from 'dto/ticket';
import useAuth from 'hooks/useAuth';
import { permission } from 'config/permission';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();

  const auth = useAuth();

  const isDesktop = useResponsive('up', 'lg');
  const res = useQuery(
    'dev-dashboard/email-template',
    () => curd.getAll({ url: `dev-dashboard/email-template` }),
    {
      enabled: window?.location?.hostname.includes(
        process.env.REACT_APP_PREFIX_SUPER_DEV_ADMIN_DOMAIN as string
      ),
    }
  );

  const GetSidebar = () => {
    if (
      window?.location?.hostname.includes(
        process.env.REACT_APP_PREFIX_SUPER_DEV_ADMIN_DOMAIN as string
      )
    ) {
      const res1 = res.data?.data.data.map((item: any) => {
        return {
          ...item,
          path: `/dashboard/email-template-dev/${item.key}`,
          title: `${item.name}`,
          icon: <EmailIcon fontSize='small' />,
        };
      });

      const headersRes: any = useQuery('dev-dashboard/email-template/file/header', () =>
        curd.getAll({ url: `dev-dashboard/email-template/file/header` })
      );

      const footersRes: any = useQuery('dev-dashboard/email-template/file/footer', () =>
        curd.getAll({ url: `dev-dashboard/email-template/file/footer` })
      );

      let headerItems: any[] = [],
        footerItems: any[] = [],
        styleItems: any = {};
      if (headersRes.data?.data?.data) {
        headerItems = headersRes.data?.data?.data.map((header: any) => {
          return {
            title: `${header?.name} File`,
            path: `/dashboard/email-template-dev/header/${header?.id}`,
            icon: <HTMLIcon fontSize='small' />,
          };
        });
      }
      if (footersRes.data?.data?.data) {
        footerItems = footersRes.data?.data?.data.map((footer: any) => {
          return {
            title: `${footer?.name} File`,
            path: `/dashboard/email-template-dev/footer/${footer?.id}`,
            icon: <HTMLIcon fontSize='small' />,
          };
        });
      }
      styleItems = {
        title: 'Style File',
        path: `/dashboard/email-template-dev/style-file`,
        icon: <CSSIcon fontSize='small' />,
      };
      devAdminSections[0].items = [
        {
          title: 'Style File',
          path: '/dashboard/email-template-dev/style-file',
          icon: <CSSIcon fontSize='small' />,
          children: [styleItems, ...headerItems, ...footerItems],
        },
      ];
      if (res1) {
        devAdminSections[0].items.push({
          title: 'Email Template',
          path: '/dashboard/email-template-dev/email-template',
          icon: <EmailIcon fontSize='small' />,
          children: [...res1],
        });
      }

      return devAdminSections;
    } else if (
      window?.location?.hostname.includes(process.env.REACT_APP_PREFIX_SUPER_ADMIN_DOMAIN as string)
    )
      return superAdminSections;
    else {
      const supportSystemSidebarSection = sections[0]?.items?.find(
        (i) => i.title == 'Support System'
      );

      if (supportSystemSidebarSection) {
        const ticketStatuses = [
          TicketStatuses.OPEN,
          TicketStatuses.IN_PROGRESS,
          TicketStatuses.CLOSED,
          'all',
        ];

        const waitingReplyURL = `dashboard/ticket/my-tickets?text=&page=0&limit=1&priority=all&status=all&new_status=all&reply_status=${TicketReplyStatuses.WAITING_REPLY}`;

        let ticketsCountResponse = useQuery(
          waitingReplyURL,
          () => curd.getAll({ url: waitingReplyURL }),
          {
            refetchInterval: 1000 * 120,
          }
        );

        const ticketsCountData = ticketsCountResponse?.data?.data?.data || null;

        if (ticketsCountData != null) {
          const children = supportSystemSidebarSection.children as any[];
          const found = children.find((c: any) =>
            c.path.endsWith(TicketReplyStatuses.WAITING_REPLY)
          );
          if (found) {
            found.rightData = <>{ticketsCountData.count}</>;
          }
        }

        for (let i = 0; i < ticketStatuses.length; i++) {
          const ticketStatus = ticketStatuses[i];
          let newStatus = 'all';
          if (ticketStatus == TicketStatuses.OPEN) newStatus = 'new';
          const url = `dashboard/ticket/my-tickets?text=&page=0&limit=1&priority=all&new_status=${newStatus}&`;

          ticketsCountResponse = useQuery(
            `${url}status=${ticketStatus}`,
            () => curd.getAll({ url: `${url}status=${ticketStatus}` }),
            {
              refetchInterval: 1000 * 120,
            }
          );

          const ticketsCountInStatusData = ticketsCountResponse?.data?.data?.data || null;
          if (ticketsCountInStatusData != null) {
            const children = supportSystemSidebarSection.children as any[];
            const found = children.find((c: any) => c.path.endsWith(ticketStatus));
            if (found) {
              found.rightData = <>{ticketsCountInStatusData.count}</>;
            }
          }
        }
      }

      return sections;
    }
  };

  const { isCollapse, collapseClick, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            px: '26px',
            mb: '34px',
            mt: '16px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <RouterLink to='/dashboard'>
            <Logo
              sx={{
                height: '26px',
                width: '126px',
                marginInlineStart: isCollapse ? '-10px' : undefined,
              }}
            />
          </RouterLink>
          {isDesktop && !isCollapse && (
            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          )}
        </Box>

        <Box sx={{ p: 2 }}>
          {(GetSidebar() as any[]).map((section: any) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (res.isLoading) return <></>;

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor='left'
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              // backgroundColor: 'background.paper',
              backgroundColor: 'background.paper',
              width: NAVBAR.DASHBOARD_WIDTH,

              zIndex: 200,
            },
          }}
          variant='temporary'
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              zIndex: 200,
              width: NAVBAR.DASHBOARD_WIDTH,
              border: 'none',
              bgcolor: 'background.paper',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: `${NAVBAR.DASHBOARD_COLLAPSE_WIDTH + 60}px`,
              }),
              ...(!isCollapse && {
                width: NAVBAR.DASHBOARD_WIDTH,
              }),
            },
          }}
          variant='persistent'
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default DashboardSidebar;
