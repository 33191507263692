import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { formatDistanceToNowStrict } from 'date-fns';
import Image from 'components/image/Image';
import React, { useState } from 'react';
import { Lightbox } from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface Props {
  message: any;
}

export const Message: FC<Props> = ({ message }) => {
  const [open, setOpen] = useState(false);
  const content = (
    <div style={{ whiteSpace: 'pre-line' }}>
      {message?.content.split('\n').map((line: any, index: any) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </div>
  );

  if (message.sender_details.type === 'admin')
    return (
      <Box>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 2 }}>
          {/* <MyAvatar
            isImage={!!message.sender_details.photo_url}
            name={'vidnik'}
            url={message?.sender_details?.photo_url}
          /> */}
          <Image
            sx={{
              borderRadius: '50%',
              width: 34,
              height: 34,
            }}
            component='img'
            loading='lazy'
            src={message?.sender_details?.photo_url}
            name={'vidnik'}
          />
          <Typography sx={{ color: 'primary.main' }}>Vidnik</Typography>
        </Box>
        <Typography sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>{content}</Typography>
      </Box>
    );
  return (
    <Box
      sx={{
        background: '#00000066',
        px: 4,
        py: 2,
        borderRadius: 1,
        gap: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        {/* <MyAvatar
          isImage={!!message.sender_details.photo_url}
          name={message?.sender_details?.full_name}
          url={message?.sender_details?.photo_url}
        /> */}
        <Image
          sx={{
            borderRadius: '50%',
            width: 34,
            height: 34,
          }}
          component='img'
          loading='lazy'
          src={message?.sender_details?.photo_url}
          name={message?.sender_details?.full_name}
        />
        <Typography>{message?.sender_details?.full_name}</Typography>
      </Box>
      <Typography sx={{ color: 'text.secondary' }}>{content}</Typography>
      {message.type === 'photo' ? (
        <Box onClick={() => setOpen(true)} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Lightbox
            index={0}
            open={open}
            close={() => setOpen(false)}
            slides={[{ src: message.file_link || '' }].concat(
              [message.file_link]?.map((item) => {
                return {
                  src: item,
                };
              })
            )}
            plugins={[Fullscreen, Slideshow, Zoom]}
          />
          <Box
            component={LazyLoadImage}
            effect={'blur'}
            placeholderSrc={'/assets/placeholder.svg'}
            sx={{
              width: '200px',
              height: '250px',
              borderRadius: '5px',
              objectFit: 'contain',
              display: 'inline-flex !important',
            }}
            src={message.file_link}
          />
        </Box>
      ) : message.type === 'file' ? (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', maxWidth: 'fit-content' }}>
          <Typography>{content}</Typography>
          <br />
          <a
            href={message.file_link}
            target='_blank'
            rel='noopener noreferrer' // Recommended for security when using target='_blank'
            style={{
              textDecoration: 'none',
              color: '#1976d2', // Change the color to match your theme
              fontWeight: 'bold', // Make the text bold
              cursor: 'pointer', // Show pointer cursor when hovering
            }}
          >
            <span>&#x1F4E5;</span>
          </a>
        </Box>
      ) : (
        <></>
      )}
      <Typography sx={{ color: 'text.secondary', mt: -2, fontSize: '12px' }}>
        {/* <AccessTimeIcon sx={{ fontSize: '12px' }} />{' '} */}
        {formatDistanceToNowStrict(new Date(message?.created_at))} ago
      </Typography>
    </Box>
  );
};
